import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import entity from "redux/slices/entity";
import form from "redux/slices/form";
import layout from "redux/slices/layout";
import user from "redux/slices/user";
import template from "./slices/template";
import history from "router/history";

const entityId =
  history?.location?.pathname?.split("/feedback/")?.[1]?.split("/")?.[0] ||
  history?.location?.pathname?.split("/hotel/")?.[1]?.split("/")?.[0] ||
  "";
const productName =
  history.location.pathname?.split("/productName/")?.[1]?.split("/")?.[0] || "";

const rootPersistConfig = {
  key: `${entityId}-${productName}-root`,
  storage: storage,
  whitelist: [],
};

const entityPersistConfig = {
  key: `${entityId}-${productName}-entity`,
  storage: storage,
};

const layoutPersistConfig = {
  key: `${entityId}-${productName}-layout`,
  storage: storage,
};

const formPersistConfig = {
  key: `${entityId}-${productName}-form`,
  storage: storage,
};

const templatePersistConfig = {
  key: `${entityId}-${productName}-template`,
  storage: storage,
};

const rootReducer = combineReducers({
  routing: routerReducer,
  user,
  entity: persistReducer(entityPersistConfig, entity),
  layout: persistReducer(layoutPersistConfig, layout),
  form: persistReducer(formPersistConfig, form),
  template: persistReducer(templatePersistConfig, template),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export default persistedReducer;
